html {
  font-family: system-ui, sans-serif;
  font-size: 1.25rem;
}

body {
  margin: 0;
  padding: 0 1.5rem;
}

a {
  color: orange;
}

a:hover {
  color: orange;
}

svg {
  display: inline-block;
  vertical-align: middle;
}
